import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 兑换码-校验券码
export function checkCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/checkCode/pageType`,
    method: 'post',
    data
  })
}
// 兑换码-发送验证码
export function getVerifyCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/sendVerifyCode`,
    method: 'post',
    data
  })
}
// 兑换码-验证手机号
export function checkPhone(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/verify`,
    method: 'post',
    data
  })
}
// 直充兑换码--获取图片验证码
export function getCaptcha(data) {
  return request({
    url: `${serivceBase.IMS}/captcha/get`,
    method: 'get',
    data
  })
}
// 直充兑换码-绑定账号
export function bindAccount(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/bindAccount/withCaptcha`,
    method: 'post',
    data
  })
}
// 校验券码（涂层刮码页面调用）
export function checkCoating(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/checkCode/coating/${data}`,
    method: 'post',
    data
  })
}
// 兑换码兑换(涂层刮码调用)
export function verifyCoating(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/coating/verify`,
    method: 'post',
    data
  })
}
// 兑换码兑换下发短信(涂层串码兑换调用)
export function coatingSend(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/coating/sendSms`,
    method: 'post',
    data
  })
}
// 根据券码编号查询已出库券码信息
export function getOutStorageInfo(data, code) {
  return request({
    url: `${serivceBase.IMS}/outStorageDetails/info/${data}/${code}`,
    method: 'get',
    data
  })
}
