<template>
  <div
    :class="
      [1].includes(type) ? 'mailesong' : [2].includes(type) ? 'xbk' : 'coupon'
    "
  >
    <div class="wrap">
      <div class="card-bag" v-if="cardBagShow" @click="toCardBag">我的卡包</div>
      <div class="form">
        <div class="form-content">
          <van-form ref="form" @submit="subSuccess">
            <ul class="form-ul">
              <li>
                <van-field
                  v-model="form.code"
                  placeholder="请输入兑换码"
                  :rules="rules.code"
                  name="pattern"
                  :maxlength="16"
                  label="兑换码："
                  @blur="redeemChange"
                ></van-field>
              </li>
              <li>
                <van-field
                  v-model="form.phone"
                  placeholder="请输入手机号"
                  :maxlength="11"
                  :rules="rules.phone"
                  label="手机号："
                  type="tel"
                  :disabled="phoneInputDisable"
                ></van-field>
              </li>
              <li class="tow-para">
                <van-field
                  v-model="form.captcha"
                  placeholder="请输入验证码"
                  :maxlength="6"
                  :rules="rules.captcha"
                  label="验证码："
                  type="number"
                ></van-field>
                <div
                  :class="
                    subBtnDisable
                      ? 'btn is-disable'
                      : btn.disable
                      ? 'btn is-disable'
                      : 'btn'
                  "
                  @click="getCode"
                >
                  {{
                    subBtnDisable
                      ? '获取验证码'
                      : btn.disable
                      ? `${btn.num}s`
                      : '获取验证码'
                  }}
                </div>
              </li>
            </ul>
          </van-form>
        </div>
        <div
          :class="subBtnDisable ? 'btn-sub is-disable' : 'btn-sub'"
          @click="submit"
        >
          立即兑换
        </div>
      </div>
      <div class="rule">
        <h2><span>兑换规则</span></h2>
        <CouponRule />
      </div>
      <a class="beian" href="https://beian.miit.gov.cn/">
        闽ICP备14019793号-8
      </a>
    </div>

    <van-dialog
      v-model="dialog.show"
      :showConfirmButton="false"
      class="cus-dialog"
    >
      <h3 class="title">温馨提示！</h3>
      <div class="msg">
        <p v-html="dialog.msg"></p>
        <ul
          v-if="
            dialog.ulMsgShow &&
              dialog.redeCodeStatus == 2 &&
              dialog.gcAttribute == 3
          "
        >
          <li>
            <span>充值商品：</span>
            <em>{{ dialog.goodsName }}</em>
          </li>
          <li>
            <span>充值账号：</span>
            <em>{{ dialog.account }}</em>
          </li>
          <li>
            <span>充值时间：</span>
            <em>{{
              this.common.formatDate(dialog.rechargeTime, '{y}-{m}-{d}')
            }}</em>
          </li>
        </ul>
        <ul
          v-if="
            dialog.ulMsgShow &&
              dialog.redeCodeStatus === 2 &&
              dialog.gcAttribute != 3
          "
        >
          <li>
            <span>兑换商品：</span>
            <em>{{ dialog.goodsName }}</em>
          </li>
          <li>
            <span>手机号码：</span>
            <em>{{ dialog.account }}</em>
          </li>
          <li>
            <span>兑换时间：</span>
            <em>{{ this.common.formatDate(dialog.excTime, '{y}-{m}-{d}') }}</em>
          </li>
        </ul>
        <ul v-if="dialog.ulMsgShow && dialog.redeCodeStatus === 3">
          <li>
            <span>过期时间：</span>
            <em>{{
              this.common.formatDate(dialog.expireTime, '{y}-{m}-{d}')
            }}</em>
          </li>
        </ul>
      </div>
      <div class="confirm" @click="dialogSure">确认</div>
    </van-dialog>

    <van-dialog
      v-model="promptVisible"
      :showConfirmButton="false"
      class="cus-dialog"
    >
      <h3 class="title">温馨提示！</h3>
      <div class="msg">请确认充值账户，确认提交后不退不换</div>
      <div class="operations">
        <div class="confirm" style="margin-right: 10px;" @click="submitConfirm">
          确认
        </div>
        <div class="confirm cancel" @click="promptVisible = false">取消</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { checkCode, getVerifyCode, checkPhone } from '@/api'
import CouponRule from '@/views/components/CouponRule.vue'
export default {
  components: { CouponRule },
  data() {
    const phoneVal = val => {
      return /^1\d{10}$/.test(val)
    }
    return {
      cardBagShow: this.$route.query.cb == 1,
      type: Number(this.$route.query.type), // 麦乐送:1,星巴克：2
      form: {
        code: this.$route.query.code || undefined,
        phone: '',
        captcha: '',
        id: ''
      },
      rules: {
        code: [{ required: true, message: '请输入兑换码' }],
        phone: [{ validator: phoneVal, message: '请输入正确手机号' }],
        captcha: [{ required: true, message: '请输入验证码' }]
      },
      btn: {
        disable: false,
        timer: null,
        num: 60
      },
      dialog: {
        show: false,
        msg: '',
        ulMsgShow: false
      },
      subBtnDisable: true,
      phoneInputDisable: false,
      promptVisible: false
    }
  },
  created() {
    if (this.$route.query.code) {
      this.CheckCode()
    }
  },
  methods: {
    toCardBag() {
      // 跳转卡包页面
      window.location.href = process.env.VUE_APP_HTTP_KB
    },
    initForm() {
      this.form.phone = ''
      this.form.id = ''
      this.form.captcha = ''
    },
    CheckCode() {
      this.subBtnDisable = true
      const obj = {
        code: this.form.code,
        redeemCodePageType: 0
      }
      checkCode(obj).then(res => {
        if (res.code === 0) {
          const data = res.data
          if (data.routeFlag === 0) {
            // if (data.phone){
            //     this.form.phone = data.phone;
            //     this.phoneInputDisable = true;
            // } else{
            //     this.phoneInputDisable = false;
            // }
            this.phoneInputDisable = false
            this.form.id = data.id
            this.subBtnDisable = false
          } else if (data.routeFlag === 2) {
            this.initForm()
            this.dialog.msg = res.message
            for (const k in data) {
              this.dialog[k] = data[k]
            }
            this.dialog.show = true
            this.dialog.ulMsgShow = true
          } else {
            this.initForm()
            this.dialog.msg = '兑换类型不匹配，无法兑换！'
            this.dialog.show = true
          }
        } else {
          this.initForm()
          this.phoneInputDisable = false
          this.dialog.show = true
          if (res.data && res.data.checkRedeemCodeErrorCode) {
            switch (res.data.checkRedeemCodeErrorCode.code) {
              case -1:
                {
                  this.dialog.msg = '兑换码不存在'
                }
                break
              case 1:
                {
                  this.dialog.msg = '该兑换码在兑换中'
                }
                break
              case 2:
                {
                  this.dialog.msg = '该兑换码正在售后中，无法兑换！'
                }
                break
              case 3:
                {
                  this.dialog.msg = '该兑换码已退款退券，无法兑换！'
                }
                break
              case 4:
                {
                  this.dialog.msg = '该兑换码已冻结，无法兑换！'
                }
                break
              case 5:
                {
                  this.dialog.msg = '该兑换码已使用'
                }
                break
              case 6:
                {
                  this.dialog.msg = '兑换时间已过期'
                }
                break
              case 7:
                {
                  this.dialog.msg = `兑换时间未开始，有效兑换时间：<br>${this.common.formatDate(
                    res.data.effectiveStartTime,
                    '{y}-{m}-{d}'
                  )}~${this.common.formatDate(
                    res.data.effectiveEndTime,
                    '{y}-{m}-{d}'
                  )}`
                }
                break
              case 8:
                {
                  this.dialog.msg = '兑换类型不匹配'
                }
                break
              case 9:
                {
                  this.dialog.msg = '兑换页面类型不匹配'
                }
                break
              case 10:
                {
                  this.dialog.msg = '兑换手机号码信息不存在'
                }
                break
              case 11:
                {
                  this.dialog.msg = '此兑换码已激活'
                }
                break
              default:
                {
                  this.dialog.msg = res.message
                }
                break
            }
          } else {
            this.dialog.msg = res.message
          }
        }
      })
    },
    redeemChange(event) {
      // 兑换码变化
      if (this.form.code) {
        this.CheckCode()
      }
    },
    submit() {
      // 立即兑换
      if (!this.subBtnDisable) {
        if ([1, 4].includes(+this.$route.query.type)) {
          this.promptVisible = true
        } else {
          this.$refs.form.submit()
        }
      }
    },
    submitConfirm() {
      this.promptVisible = false
      this.$refs.form.submit()
    },
    subSuccess() {
      // 表单验证成功后事件
      const { id, phone, captcha, code } = this.form
      const obj = {
        code: captcha,
        id,
        phone,
        redeemCode: code
      }
      checkPhone(obj).then(res => {
        if (res.code === 0) {
          this.dialog.msg = res.message
          this.dialog.show = true
        } else {
          this.dialog.msg = res.message
          this.dialog.show = true
        }
      })
    },
    getCode() {
      // 获取验证码
      if (!this.btn.disable && !this.subBtnDisable) {
        this.$refs.form.validate('pattern').then(val => {
          // 验证通过触发事件
          const obj = {
            id: this.form.id,
            phone: this.form.phone
          }
          getVerifyCode(obj).then(res => {
            if (res.code === 0) {
              this.btn.num = 60
              this.btn.disable = true
              this.btn.timer = setInterval(() => {
                this.btn.num--
                if (this.btn.num <= 0) {
                  this.btn.disable = false
                  this.btn.timer = null
                }
              }, 1000)
            } else {
              alert(res.message)
            }
          })
        })
      }
    },
    dialogSure() {
      // 关闭弹窗
      this.dialog.show = false
      setTimeout(() => {
        this.dialog.ulMsgShow = false
      }, 200)
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/comCouRecStyle.less';

.operations {
  display: flex;
  .cancel {
    background-color: #999;
  }
}
.beian {
  display: block;
  text-align: center;
  padding: 20px 0;
}
</style>
